import React from 'react'

import { platformMap, currentMonth, currentMonthShort } from '../utils/constants';

import { blockfiWithdrawalsRaw, blockfiRowInfoUS, blockfiRates } from  './blockfi-info';
import { celsiusRates, celsiusRowInfoUS } from './celsius-info';
import { haruRates, haruWithdrawals, haruInfoUS } from './haru-info';
import { hodlnautRates, hodlnautWithdrawalsRaw, hodlnautInfoUS } from './hodlnaut-info';
import { geminiRates, geminiWithdrawalsRaw, geminiRowInfoUS } from './gemini-info';
import { cryptoComWithdrawals, cryptoComRates, cryptoComRowInfoUS } from './crypto-com-info';
import { lednRates, lednWithdrawals, lednInfoInt } from './ledn-info';
import { finbloxRates, finbloxRowInfoInt } from './finblox-info';
import { nexoRates, nexoRowInfoInt } from './nexo-info';
import { vauldRates, vauldRowInfoUS } from './vauld-info';

const geminiExcerpt = <span><b>IMPORTANT:</b> The lending partner of Gemini Earn (<a target="_blank" rel="noopener" href="https://www.coindesk.com/business/2022/11/16/genesis-crypto-lending-unit-is-halting-customer-withdrawals-in-wake-of-ftx-collapse/">Genesis</a>) has paused withdrawals (this only affects Gemini Earn, not any other Gemini products / services). <a target="_blank" rel="noopener" href="https://www.gemini.com/blog/an-important-message-regarding-gemini-earn">Gemini Announcement</a>.<hr/>The table below shows the Gemini Earn withdrawal fees of {currentMonth} 2024, for each supported cryptocurrency and stablecoin. <b>Note:</b> These are only the assets that are part of the Gemini Earn program.<br/><br/>As you'll notice in the table, most assets require you to pay the network fees to withdraw. This applies to ETH and ERC-20 tokens, where you'll need to pay the Ethereum network fees. These fees will vary based on network activity. For non-Ethereum withdrawals, such as BTC or DOGE, there is a flat fee.</span>;
const hodlnautExcerpt = `With Hodlnaut, you get one free crypto withdrawal per month (completely free, they cover network fees). The table below shows the withdrawal fees for each supported cryptocurrency and stablecoin, after your one free withdrawal:`;
const cryptoComExcerpt = <span>The table below shows the Crypto.com Earn withdrawal fees of {currentMonth} 2024, for each supported cryptocurrency and stablecoin. <b>Note:</b> These are the withdrawal fees for <em>only</em> the assets that are part of the Crypto Earn program.</span>;

const cryptoComCustomDescription = `Crypto.com Earn withdrawal fees of ${currentMonthShort} 2024, for each supported cryptocurrency and stablecoin. See the cost to withdraw each asset in the Crypto.com Earn program.`;

export const slugToWithdrawalFeesMap = {
    [platformMap.blockfi.withdrawalSlug]: {
        ...platformMap.blockfi,
        withdrawalFees: blockfiWithdrawalsRaw,
        bonusHTML: blockfiRates.bonusHTML,
        bonus: blockfiRowInfoUS.bonus,
    },
    [platformMap.gemini.withdrawalSlug]: {
        ...platformMap.gemini,
        withdrawalFees: geminiWithdrawalsRaw,
        bonusHTML: geminiRates.bonusHTML,
        bonus: geminiRowInfoUS.bonus,
        customExcerpt: geminiExcerpt,
    },
    [platformMap.celsius.withdrawalSlug]: {
        ...platformMap.celsius,
        bonusHTML: celsiusRates.bonusHTML,
        bonus: celsiusRowInfoUS.bonus,
    },
    [platformMap.hodlnaut.withdrawalSlug]: {
        ...platformMap.hodlnaut,
        withdrawalFees: hodlnautWithdrawalsRaw,
        bonusHTML: hodlnautRates.bonusHTML,
        bonus: hodlnautInfoUS.bonus,
        customExcerpt: hodlnautExcerpt,
    },
    [platformMap.cryptoCom.withdrawalSlug]: {
        ...platformMap.cryptoCom,
        withdrawalFees: cryptoComWithdrawals,
        bonusHTML: cryptoComRates.bonusHTML,
        name: 'Crypto.com App',
        bonus: cryptoComRowInfoUS.bonus,
        customExcerpt: cryptoComExcerpt,
        customDescription: cryptoComCustomDescription,
    },
    [platformMap.haru.withdrawalSlug]: {
        ...platformMap.haru,
        withdrawalFees: haruWithdrawals,
        bonusHTML: haruRates.bonusHTML,
        bonus: haruInfoUS.bonus,
    },
    [platformMap.ledn.withdrawalSlug]: {
        ...platformMap.ledn,
        withdrawalFees: lednWithdrawals,
        bonusHTML: lednRates.bonusHTML,
        bonus: lednInfoInt.bonus,
    },
    [platformMap.finblox.withdrawalSlug]: {
        ...platformMap.finblox,
        bonusHTML: finbloxRates.bonusHTML,
        bonus: finbloxRowInfoInt.bonus,
    },
    [platformMap.nexo.withdrawalSlug]: {
        ...platformMap.nexo,
        bonusHTML: nexoRates.bonusHTML,
        bonus: nexoRowInfoInt.bonus,
    },
    [platformMap.vauld.withdrawalSlug]: {
        ...platformMap.vauld,
        bonusHTML: vauldRates.bonusHTML,
        bonus: vauldRowInfoUS.bonus,
    },
    // [slugVoyagerWithdrawalFees]: voyagerWithdrawals,
    // [slugAbraWithdrawalFees]: abraWithdrawals,
};
